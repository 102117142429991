import React, { CSSProperties, ReactElement } from 'react';
import clsx from 'clsx';
import Button from '@components/Button-2';
import Image from '@components/Image';
import Tag from '@components/Tag';
import { Typography } from '@components/Typography';
import AemLink from '@components/AemLink';
import { IHeroImage, VerticalAlignmentEnum } from './types';
import { HeadingStyles, HeadingTags } from '@components/Typography/Heading/types';
import { TagVariantsEnum } from '@components/Tag/types';
import { ButtonSizeEnum } from '@components/Button-2/types';
import styles from './styles/heroImage.module.scss';
import { IImageObjectProps } from '@components/Image/types';

const HeroImage = ({
    buttons,
    componentTag,
    data,
    description,
    hasOverlay,
    heading,
    heightType = '50',
    image,
    overlayColor,
    textAlignment,
    time,
    verticalAlignment = VerticalAlignmentEnum.BOTTOM,
    video,
    uuid,
}: IHeroImage): ReactElement => {
    const hasMedia = !!video?.path || !!image?.fileReference;

    const contentClasses = clsx(
        styles.content,
        styles[textAlignment as string],
        styles[`valign${verticalAlignment}`],
        !hasMedia && styles.static,
    );

    return (
        <section className={styles.root} data-mt-type={uuid}>
            {hasMedia && (
                <div
                    className={clsx(
                        styles.image,
                        styles[`image--${heightType}`],
                        hasOverlay && styles.overlay,
                        styles[`overlay--${verticalAlignment}`],
                    )}
                    style={{ '--hero-image-overlay': overlayColor } as CSSProperties}
                >
                    {video?.path ? (
                        <video className={styles.video} autoPlay loop muted>
                            <source src={video.path} type={video.type} />
                        </video>
                    ) : (
                        <Image
                            image={
                                {
                                    dimensionsType: 'full',
                                    ...image,
                                } as IImageObjectProps
                            }
                            classes={{
                                image: styles.imageWrap,
                                link: styles.imageWrap,
                                wrapper: styles.imageWrap,
                            }}
                        />
                    )}
                </div>
            )}
            <div className={contentClasses}>
                {image.url && <AemLink to={image.url} className={styles.imageLink} />}
                <div className={styles.text}>
                    {componentTag && (
                        <Tag classes={styles.line} variant={TagVariantsEnum.PROMOTION}>
                            {componentTag}
                        </Tag>
                    )}
                    {heading?.text && (
                        <Typography.Heading
                            style={HeadingStyles.DISPLAY}
                            tag={heading?.tag || HeadingTags.H1}
                            className={styles.line}
                        >
                            {heading.text}
                        </Typography.Heading>
                    )}
                    {(data || time) && (
                        <Typography.Body className={clsx(styles.info, styles.line)}>
                            {data && <span>{data}</span>}
                            {time && <span>{time}</span>}
                        </Typography.Body>
                    )}
                    {description && <Typography.Body className={styles.description}>{description}</Typography.Body>}
                    {buttons?.length ? (
                        <div
                            className={clsx(
                                styles.buttons,
                                buttons.length >= 2 && styles['buttons--multiple'],
                                textAlignment && styles[`buttons${textAlignment}`],
                            )}
                        >
                            {buttons.map((button) => (
                                <Button key={button.label} {...button} sizeMobile={ButtonSizeEnum.SMALL} />
                            ))}
                        </div>
                    ) : null}
                </div>
            </div>
        </section>
    );
};

export default HeroImage;
