import React, { ReactElement } from 'react';
import clsx from 'clsx';
import AemLink from '@components/AemLink';
import { ITag, TagVariantsEnum } from './types';
import Icon from '@components/Icon';
import { IconTypesEnum } from '@components/Icon/types';
import styles from './styles/tag.module.scss';

const Tag = ({ children, classes, onClick, to, variant, ...restProps }: ITag): ReactElement => {
    const className = clsx(
        styles.root,
        classes,
        variant === TagVariantsEnum.PROMOTION && styles[variant],
        variant === TagVariantsEnum.COMPLETE && styles[variant],
        variant === TagVariantsEnum.CANCELLED && styles[variant],
        variant === TagVariantsEnum.PROCESSING && styles[variant],
        variant === TagVariantsEnum.PENDING && styles[variant],
    );

    if (
        variant === TagVariantsEnum.DISMISSABLE ||
        variant === TagVariantsEnum.ACTIONABLE ||
        variant === TagVariantsEnum.BLACK
    ) {
        const classNameButton = clsx(
            className,
            variant === TagVariantsEnum.DISMISSABLE && styles.dismissable,
            variant === TagVariantsEnum.ACTIONABLE && styles.actionable,
            variant === TagVariantsEnum.BLACK && styles.black,
        );

        if (to) {
            return (
                <AemLink className={classNameButton} to={to} {...restProps}>
                    {children}
                </AemLink>
            );
        }

        return (
            <button className={classNameButton} onClick={onClick} {...restProps}>
                {children}
                {variant === TagVariantsEnum.DISMISSABLE && (
                    <Icon name={'close'} type={IconTypesEnum.ICON} size={'18'} />
                )}
            </button>
        );
    }

    return (
        <span className={className} {...restProps}>
            {children}
        </span>
    );
};

export default Tag;
