import React, { ReactElement } from 'react';
import { IconTypesEnum } from '@components/Icon/types';
import { IStarRatingProps, IStarProps } from './types';
import Icon from '@components/Icon';
import styles from './styles/starRating.module.scss';

const COUNT_STARS = 5;
const MIN_VALUE = 0.2;
const MAX_VALUE = 0.7;

const Star = ({ index, rating }: IStarProps): ReactElement => {
    if (index + MAX_VALUE < rating) return <Icon name={'star_filled'} type={IconTypesEnum.ICON} size={'12'} />;
    if (index + MIN_VALUE >= rating) return <Icon name={'star_stroke'} type={IconTypesEnum.ICON} size={'12'} />;

    return <Icon name={'star_half'} type={IconTypesEnum.ICON} size={'12'} />;
};

const StarRating = ({ isShowEmptyStars, isYotpoEnabled, yotpoRating }: IStarRatingProps): ReactElement | null => {
    if (!isYotpoEnabled || !yotpoRating) return null;

    const { average_score: score } = yotpoRating || {};

    return (
        <div className={styles.starsContainer}>
            {isShowEmptyStars || (score && score > 0) ? (
                <div className={styles.starsRatings}>
                    {[...Array(COUNT_STARS)].map((_, i) => (
                        <Star rating={score} index={i} key={`star-${i}`} />
                    ))}
                </div>
            ) : null}
        </div>
    );
};

export default StarRating;
