import React, { ReactElement } from 'react';
import clsx from 'clsx';
import Icon from '@components/Icon';
import type { IButtonSelector } from './types';
import { IconTypesEnum } from '@components/Icon/types';
import AemLink from '@components/AemLink';
import styles from './styles/buttonSelector.module.scss';

const ButtonSelector = ({
    active,
    className,
    disabled = false,
    icon,
    label,
    onClick,
    openInNewWindow,
    to,
    ...rest
}: IButtonSelector): ReactElement | null => {
    const classes = clsx(className, styles.root, active && styles.active, disabled && styles.disabled);
    const content = icon ? <Icon name={icon} type={IconTypesEnum.ICON} /> : label;

    if (to) {
        const openInNewWindowAttribute = openInNewWindow
            ? {
                  rel: 'noreferrer',
                  target: '_blank',
              }
            : null;

        if ((to as string).startsWith('http')) {
            return (
                <a className={classes} href={to} {...openInNewWindowAttribute} {...rest}>
                    {content}
                </a>
            );
        }

        return (
            <AemLink className={classes} to={to as string} {...openInNewWindowAttribute} {...rest}>
                {content}
            </AemLink>
        );
    }

    return (
        <button
            aria-disabled={disabled}
            aria-label={label}
            className={classes}
            disabled={disabled}
            onClick={onClick}
            title={label}
            type={'button'}
            {...rest}
        >
            {content}
        </button>
    );
};

export default ButtonSelector;
