import type { ReactElement } from 'react';
import type { IVisibility } from '@components/Visibility/types';
import { AlignmentEnum } from 'src/types';

export enum ButtonVariantsEnum {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    TERTIARY = 'tertiary',
    TRIGGER = 'trigger',
    OUTLINED = 'outlined',
    LINK = 'link',
    // will be deprecated
    BLACK = 'black',
    WHITE = 'white',
}

export enum ButtonSizeEnum {
    DEFAULT = 'default',
    SMALL = 'small',
}

export interface IButtonVisualisation {
    alignment: AlignmentEnum;
    size: ButtonSizeEnum;
    type: ButtonVariantsEnum;
}

export interface IButton {
    children?: ReactElement | string;
    classes?: string;
    className?: string;
    component?: 'link' | 'button';
    disabled?: boolean;
    hidden?: boolean;
    isFullWidth?: boolean;
    label?: string;
    openInNewWindow?: boolean;
    size?: ButtonSizeEnum | null;
    sizeMobile?: ButtonSizeEnum;
    to?: string;
    type?: 'submit' | 'button' | 'reset';
    variant?: ButtonVariantsEnum;
    visualisation?: IButtonVisualisation;
    onClick?: () => void;
    uuid?: string;
}

export interface IButtonAemProps extends IVisibility, IButton {}
