import React, { ReactElement } from 'react';
import clsx from 'clsx';
import AemLink from '@components/AemLink';
import Icon from '@components/Icon';
import type { ILink } from './types';
import { IconTypesEnum } from '@components/Icon/types';
import styles from './styles/link.module.scss';

const Link = ({
    className,
    disabled,
    hasHover = true,
    hasUnderline,
    iconLeft,
    iconRight,
    isButton,
    text,
    to,
    typeIcon = IconTypesEnum.ICON,
    variant = 'default',
    ...rest
}: ILink): ReactElement | null => {
    const classes = clsx(
        styles.root,
        className,
        styles[variant],
        hasUnderline && styles.underline,
        disabled && styles.disabled,
    );

    const Wrapper = ({ children }) => {
        if (isButton) {
            // In some cases, we cannot use "a" due to accessibility standards, but it should have Link styling
            // (when, there is no href, Link should behave as button).
            return (
                <button className={classes} {...rest}>
                    {children}
                </button>
            );
        } else {
            if (!to) return null;

            return (
                <AemLink className={classes} to={to} {...rest}>
                    {children}
                </AemLink>
            );
        }
    };

    return (
        <Wrapper>
            {iconLeft && <Icon name={iconLeft} type={typeIcon} size={18} />}
            {hasHover ? <span>{text}</span> : text}
            {iconRight && <Icon name={iconRight} type={typeIcon} size={18} />}
        </Wrapper>
    );
};

export default Link;
