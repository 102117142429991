import type { ReactElement } from 'react';

export enum TagVariantsEnum {
    CONTENT = 'content',
    ACTIONABLE = 'actionable',
    DISMISSABLE = 'dismissable',
    PROMOTION = 'promotion',
    COMPLETE = 'complete',
    CANCELLED = 'cancelled',
    PENDING = 'pending',
    PROCESSING = 'processing',
    BLACK = 'black',
}

export interface ITag {
    children?: ReactElement | string;
    classes?: string;
    variant?: TagVariantsEnum;
    to?: string;
    onClick?: () => void;
}
