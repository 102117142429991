import React, { ReactElement } from 'react';
import clsx from 'clsx';
import { ButtonSizeEnum, ButtonVariantsEnum, IButton } from './types';
import AemLink from '@components/AemLink';
import Icon from '@components/Icon';
import { IconTypesEnum } from '@components/Icon/types';
import styles from './styles/button.module.scss';

// TODO Helper for AEM Button. Could be deleted after approving Design 2.0 and updating button variants on AEM side
// secondary -> outlined || white -> secondary || black -> tertiary
const mappingType = (type: string): string => {
    switch (type) {
        case ButtonVariantsEnum.SECONDARY:
            return ButtonVariantsEnum.OUTLINED;

        case ButtonVariantsEnum.BLACK:
            return ButtonVariantsEnum.TERTIARY;

        case ButtonVariantsEnum.WHITE:
            return ButtonVariantsEnum.SECONDARY;

        case ButtonVariantsEnum.LINK:
            return ButtonVariantsEnum.LINK;

        default:
            return ButtonVariantsEnum.PRIMARY;
    }
};

const Button = ({
    children,
    classes,
    className: classNameJsx,
    component,
    disabled,
    hidden,
    isFullWidth,
    label,
    openInNewWindow,
    size,
    sizeMobile,
    to,
    type = 'button',
    variant,
    visualisation,
    uuid,
    ...restProps
}: IButton): ReactElement | null => {
    if (hidden) return null;

    const sanitizeRestProps = (propsObject) => {
        // Remove all custom (non html) attributes
        const { cssClass, visibility, ...rest } = propsObject;

        return rest;
    };

    const content = label || children;
    const className = clsx(
        styles.root,
        classes,
        classNameJsx,
        visualisation?.type ? styles[mappingType(visualisation.type)] : styles[variant || ButtonVariantsEnum.PRIMARY],
        visualisation?.alignment && styles[visualisation.alignment],
        (visualisation?.size === ButtonSizeEnum.SMALL || size === ButtonSizeEnum.SMALL) && styles.small,
        sizeMobile === ButtonSizeEnum.SMALL && styles.smallMobile,
        isFullWidth && styles.fullWidth,
    );
    const showIcon = visualisation?.type === ButtonVariantsEnum.LINK || variant === ButtonVariantsEnum.LINK;

    if (
        component === ButtonVariantsEnum.LINK ||
        visualisation?.type === ButtonVariantsEnum.LINK ||
        variant === ButtonVariantsEnum.LINK ||
        to
    ) {
        const classNameLink = clsx(className, disabled && styles.disabled);
        const openInNewWindowAttribute = openInNewWindow
            ? {
                  rel: 'noreferrer',
                  target: '_blank',
              }
            : null;

        if ((to as string).startsWith('http')) {
            return (
                <a
                    className={classNameLink}
                    href={to}
                    {...openInNewWindowAttribute}
                    {...sanitizeRestProps(restProps)}
                    data-mt-type={uuid}
                >
                    {content}
                </a>
            );
        }

        return (
            <AemLink
                className={classNameLink}
                to={to as string}
                {...openInNewWindowAttribute}
                {...sanitizeRestProps(restProps)}
                data-mt-type={uuid}
            >
                {content}
                {showIcon && <Icon name={'chevron-right'} type={IconTypesEnum.ICON} size={'18'} />}
            </AemLink>
        );
    }

    return (
        <button
            className={className}
            type={type}
            disabled={disabled}
            {...sanitizeRestProps(restProps)}
            data-mt-type={uuid}
        >
            {content}
        </button>
    );
};

export default Button;
